import { createI18n } from 'vue-i18n'
import en from './translations/en.json'
import it from './translations/it.json'
import fr from './translations/fr.json'
import de from './translations/de.json'
import config from '../../config.json'


const i18n = createI18n({
    locale: localStorage.getItem('session_language') || config.default_language,
    messages: {
      en,
      it,
      fr,
      de
    }
});


export default i18n;