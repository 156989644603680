<template>
    <Loader v-if="loader"/>
    <component :is="api_key"></component>
    <Version v-if="!isLoggedIn"/>
    <v-facebook-login @sdk-init="facebookSdkInit" :app-id="config.social_login.facebook.appId"  :version="'v16.0'" class="d-none"></v-facebook-login>
    <transition name="fade-modal">
        <modal v-if="errorFromServer" @close="setErrorFromServer(null)">
            <template v-slot:header>
                <div class="modal-header justify-content-between bg-danger text-white">
                    <div class="d-flex align-items-center">
                        <i class="fa-solid fa-globe me-2"></i>
                        <h5 class="modal-title">
                            {{ errorFromServer }}
                        </h5>
                    </div>
                    <div @click="this.$router.go(`${this.$route.path}`)">
                        <i class="fa-solid fa-rotate fs-4 cursor-pointer"></i>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapMutations} from 'vuex'
import VFacebookLogin from 'vue-facebook-login-component-next'
import Loader from '@/components/Loader.vue'
import Modal from '@/components/Modal.vue'
import Version from '@/components/Version.vue'
export default {
    name: 'MyTravels',
    components: {
        Loader,
        Modal,
        VFacebookLogin,
        Version
    },
    computed: {
        ...mapState(['config', 'errorFromServer', 'loader']),
        ...mapGetters(['isLoggedIn', 'apiPath', 'omsApiKey']),
        api_key() {
            return defineAsyncComponent(() => import(`../layouts/${this.omsApiKey}/App.vue`))
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer', 'setSupportedLanguages', 'setFacebookSdk', 'setLoader']),
        initApp() {
            this.setLoader(true)
            this.$http
                .get(`${this.apiPath}/initApp`, {
                    headers: {
                        "x-api-key": this.omsApiKey
                    }
                })
                .then((res) => {
                    document.title = res.data
                    this.setFavicons(`/${this.omsApiKey}.ico`)
                    this.getSupportedLanguages()
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        },
        getSupportedLanguages() {
            this.$http
                .get(`${this.apiPath}/languages`, {
                    headers: {
                        "x-api-key": this.omsApiKey
                    }
                })
                .then((res) => {
                    this.setSupportedLanguages(res.data)
                    this.setLoader(false)
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        },
        setFavicons(icon) {
            let headTitle = document.querySelector('head')
            let setFavicon = document.createElement('link')
            setFavicon.setAttribute('rel', 'icon')
            setFavicon.setAttribute('href', icon)
            headTitle.appendChild(setFavicon)
        },
        facebookSdkInit({ FB, scope }) {
            this.setFacebookSdk({ FB, scope })
        }
    },
    created() {
        this.initApp()
        this.$store.dispatch('autoLogin')
        if (!this.isLoggedIn) {
            if (this.config.supported_languages.includes(navigator.language.slice(0,2))) {
                localStorage.setItem('session_language', navigator.language.slice(0,2))
            } else {
                localStorage.setItem('session_language', this.config.default_language)
            }
        }
    }
}
</script>

<style lang="scss">
    @import "./style/general";
</style>